import {
  Subtechnique as SubtechniqueStyled,
  Subtechniques as SubtechniquesStyled,
  Technique as TechniqueStyled,
  Techniques,
  Label,
  Title,
  Wrapper,
  Item as ItemStyled,
} from "./styled";

import data from "./mitre-with-status.json";
import { useToggle } from "usehooks-ts";
import { FC, Fragment } from "react";

interface BaseItem {
  status?: number;
  label: string;
  title: string;
}
interface TechniqueProps extends BaseItem {
  items?: BaseItem[];
}

const Item: FC<TechniqueProps> = ({ status, label, title, items }) => {
  return (
    <ItemStyled data-status={status} title={label}>
      <Label>{label}</Label>
      <Title>
        {title}
        {Array.isArray(items) && items.length > 0 && (
          <small>{items.length}</small>
        )}
      </Title>
    </ItemStyled>
  );
};

const Technique: FC<TechniqueProps> = (item) => {
  const [expanded, toggle] = useToggle(false);

  return (
    <TechniqueStyled
      data-status={item.status}
      $expanded={expanded}
      onClick={toggle}
    >
      <Item {...item} />
      {Array.isArray(item.items) && (
        <SubtechniquesStyled>
          {item.items.map((item, key) => (
            <SubtechniqueStyled key={key} data-status={item.status}>
              <Item {...item} />
            </SubtechniqueStyled>
          ))}
        </SubtechniquesStyled>
      )}
    </TechniqueStyled>
  );
};

export const MitreTable = () => (
  <Wrapper>
    {data.map(({ label, title, items }, key) => (
      <Fragment key={key}>
        <h2 title={label}>{title}</h2>
        <Techniques>
          {items.map((item, key) => (
            <Technique key={key} {...item} />
          ))}
        </Techniques>
      </Fragment>
    ))}
  </Wrapper>
);
