import styled from "styled-components";

export const Row = styled("tr")<{ "data-group-type"?: string }>((props) => ({
  [`&[data-group-state=false] ~ [data-group-type='${props["data-group-type"]}']`]:
    {
      display: "none",
    },
}));

export const Wrapper = styled("div")({
  overflowX: "auto",
});

export const Techniques = styled("div")({
  display: "grid",
  gridAutoColumns: 250,
  gridAutoFlow: "dense",
  gap: 20,
  gridTemplateColumns: "repeat(6, 1fr)",
  "@media (max-width: 1280px)": {
    gridTemplateColumns: "repeat(5, 1fr)",
  },
  "@media (max-width: 1024px)": {
    gridTemplateColumns: "repeat(4, 1fr)",
  },
  "@media (max-width: 960px)": {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  "@media (max-width: 840px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
});

const statusColor = {
  '&[data-status="2"]': {
    backgroundColor: "rgba(0, 168, 142, 0.3)",
  },
  '&[data-status="1"]': {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
  },
};

export const Technique = styled("div")<{ $expanded?: boolean }>(
  ({ $expanded }) => ({
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "rgba(0,0,0,.1)",
    ...(typeof $expanded === "boolean" ? { cursor: "pointer" } : {}),
    [Subtechniques]: {
      display: $expanded ? "block" : "none",
    },
    ...statusColor,
  })
);

export const Title = styled("div")({});
export const Label = styled("label")({
  fontSize: "smaller",
});

export const Item = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 12,
});

export const Subtechniques = styled("div")({
  fontSize: "small",
  display: "none",
  borderTop: "1px solid rgba(0,0,0,.1)",
  background: "#fff",
});

export const Subtechnique = styled("div")({
  "&:not(:first-child)": {
    borderTop: "1px solid rgba(0, 0, 0, .05)",
  },
  [Item]: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  ...statusColor,
});
