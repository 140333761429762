import { Route, Routes } from "react-router-dom";
import * as mitre from "./mitre";

const router = () => (
  <Routes>
    <Route path="/mitre" element={<mitre.element />} />
    <Route path="*" element={<mitre.element />} />
  </Routes>
);

export default router;
