// import { Table } from "../../components/Compare/Table";
import CompareHeader from "../../components/CompareHeader";
import { MitreTable } from "../../components/MitreTable/MitreTable";
import Page from "../../components/Page";

export const name = "Матрица MITRE ATT&CK";
export const path = "/mitre";
export const title = `Покрытие матрицы MITRE ATT&CK в Kaspersky Unified Monitoring and Analysis Platform`;

export const element = () => (
  <Page>
    <CompareHeader>
      <p>
        MITRE ATT&CK — это публичная база знаний, в которой собраны тактики и
        техники целевых атак, применяемые различными группировками
        киберпреступников. Сегодня она считается общемировым отраслевым
        стандартом и включает сотни техник и подтехник и тысячи процедур.
        Выбирая направления развития SIEM-системы Kaspersky Unified Monitoring
        and Analysis Platform, мы во многом опираемся именно на базу знаний
        MITRE. Мы начали включать в KUMA разметку текущих правил в соответствии
        с методами и тактиками атак MITRE, чтобы расширить возможности по
        анализу угроз и визуализировать степень защиты. Мы также ориентируемся
        на MITRE, когда разрабатываем OOTB (Out-of-the-box) контент нашей
        SIEM-платформы.
      </p>
      <h2>Сколько техник покрывает KUMA?</h2>
      <p>
        Версия KUMA 3.4 покрывает 404 техники MITRE ATT&CK. Это 62% от общего
        количества техник. Этот процент будет значительно выше, если вы
        используете не только KUMA, но и другие продукты «Лаборатории
        Касперского». Ниже представлено покрытие фреймворка MITRE в KUMA по
        состоянию на декабрь 2024 года.
      </p>
    </CompareHeader>

    <MitreTable />

    {/* <Table
      products={Object.values(ProductType)}
      features={Object.values(FeatureType)}
    /> */}
  </Page>
);
