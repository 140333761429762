import { ReactNode } from "react";
import styled from "styled-components";

interface CompareHeaderProps {
  $title?: ReactNode;
  children: ReactNode;
}

const Title = styled("h2")(({ theme }) => ({
  fontWeight: 600,
  fontSize: 35,
  lineHeight: 41 / 35,
  margin: 0,
  strong: {
    color: theme.colors.kaspersky,
  },
  "&:not(:last-child)": {
    marginBottom: 20,
  },
}));
const Content = styled("div")({
  p: {
    margin: 0,
  },
});

const CompareHeader = styled("section").attrs<CompareHeaderProps>(
  ({ $title, children }) => ({
    children: (
      <>
        {$title && <Title>{$title}</Title>}
        <Content>{children}</Content>
      </>
    ),
  })
)<CompareHeaderProps>({
  marginTop: 70,
  marginBottom: 50,
});

export default CompareHeader;
