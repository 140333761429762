import styled from "styled-components";
import { Logo } from "../Logo";

const Root = styled("header")<Pick<HeaderProps, "$image">>(({ $image }) => ({
  "::before": {
    content: "''",
    backgroundPosition: "top, center",
    backgroundSize: "cover, cover",
    backgroundImage: `url("${$image}")`,
    position: "absolute",
    display: "block",
    height: "100%",
    width: "100vw",
    minWidth: 1280,
    left: "50%",
    top: 0,
    transform: "translateX(-50%)",
    zIndex: -1,
  },
  aspectRatio: "16 / 9",
  // minHeight: 715,
  position: "relative",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  color: "#fff",
}));

const Title = styled("h1")({
  // fontSize: 50,
  fontSize: 35,
  lineHeight: 58.6 / 50,
  fontWeight: 600,
  maxWidth: 860,
  width: "40%",

  marginTop: "1em",
  // marginBottom: "3em",

  "@media (max-width: 860px)": {
    fontSize: 25,
    marginTop: "3em",
  },
});
const Lead = styled("p")({
  padding: 0,
  fontSize: 20,
  lineHeight: 23.44 / 20,
  fontWeight: 400,
  maxWidth: 560,
  width: "30%",
});

interface HeaderProps {
  $image: string;
}
export const Header = ({ $image }: HeaderProps) => (
  <Root $image={$image}>
    <Logo />
    <Title>
      Покрытие матрицы MITRE ATT&CK в&nbsp;Kaspersky Unified Monitoring and
      Analysis Platform
    </Title>
    {/* <Lead>
      Покрытие матрицы MITRE ATT&CK в&nbsp;Kaspersky Unified Monitoring and
      Analysis Platform
    </Lead> */}
  </Root>
);
